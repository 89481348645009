
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerConfigurazioni = () => {
  

  return (
    
    <section className="bannerConfigurazioni" id="home">
      <h1>Scegli il tuo budget</h1>
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerConfigurazioniLink">
                  <a href="/PC500"><h5>500 €</h5></a>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerConfigurazioniLink">
                  <a href="/PC700"><h5>700 €</h5></a>
              </div>
              }
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerConfigurazioniLink">
                  <a href="/PC1000"><h5>1000 €</h5></a>
              </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}