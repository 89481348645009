
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerPC500 = () => {
  

  return (
    
    <section className="bannerPC500" id="home">
      <h1>PC da 500 €</h1>
      <Container>
        
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerPC500Link">
                  <h3>Primo PC: </h3>
                  <br></br>
                  <h5>-CPU: </h5>
                  <h7><a href="https://www.amazon.it/AMD-Ryzen-2600-Processor-Disco/dp/B07B41WS48">AMD Ryzen 5 2600 - 141,71€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Scheda madre: </h5>
                  <h7><a href="https://www.amazon.it/MSI-B450M-PRO-M2-MAX-Socket/dp/B07V9JVD3J">MSI B450M PRO-M2 MAX - 71,90€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Memoria RAM: </h5>
                  <h7><a href="https://www.amazon.it/Corsair-CMK8GX4M1D3000C16-Vengeance-LPX-3000MHz/dp/B07B4G525V">Corsair Vengeance LPX 8GB DDR4-3000 - 42,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Scheda grafica: </h5>
                  <h7><a href="https://www.amazon.it/ASUS-GT710-SL-1GD5-BRK-Scheda-Grafica/dp/B07X3ZZQB8">ASUS GT710-SL-1GD5-BRK - 40,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Archiviazione: </h5>
                  <h7><a href="https://www.amazon.it/Kingston-SA400S37-240G-Interno-Nero/dp/B01N6JQS8C">Kingston A400 240GB SSD - 27,59€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Alimentatore: </h5>
                  <h7><a href="https://www.amazon.it/EVGA-100-W1-0500-KR-Alimentatore-Intelligent/dp/B00LV8TZAG">EVGA 500W1 100-W1-0500-KR - 44,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Case: </h5>
                  <h7><a href="https://www.amazon.it/Sharkoon-VG5-W-Midi-Tower-compatibile/dp/B00TT8B5F0">Sharkoon VG5-W - 36,72€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Totale: 406,89€</h5>
                  <h7></h7>
                  <br></br>
                  <br></br>
                  <h3>FPS: </h3>
                  <br></br>
                  <h5>-GTA V: </h5>
                  <h7>tra i 30 e i 50 fps a dettagli medi a 1080p</h7>
                  <br></br>
                  <br></br>
                  <h5>-Fortnite: </h5>
                  <h7>tra i 60 e i 90 fps a dettagli medi a 1080p</h7>
                  <br></br>
                  <br></br>
                  <h5>-Minecraft: </h5>
                  <h7>oltre i 100 fps a dettagli elevati a 1080p</h7>
                  <br></br>
                  <br></br>
                  </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}