import logo from './logo.svg';
import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { BannerHome } from "./components/BannerHome";
import { BannerConfigurazioni } from "./components/BannerConfigurazioni";
import { BannerPC500 } from "./components/BannerPC500";
import { BannerPC700 } from "./components/BannerPC700";
import { BannerPC1000 } from "./components/BannerPC1000";
import { Footer } from "./components/Footer";

function App() {

  switch (window.location.pathname){
    case "/":
      return (
        <div className="App">
          <NavBar />
          <BannerHome />
          <Footer />
        </div>
      );
      break
    case "/home":
      return (
        <div className="App">
          <NavBar />
          <BannerHome />
          <Footer />
        </div>
      );
      break
    case "/configurazioni":
      return (
        <div className="App">
          <NavBar />
          <BannerConfigurazioni />
          <Footer />
        </div>
      );
      break
    case "/PC500":
      return (
        <div className="App">
          <NavBar />
          <BannerPC500 />
          <Footer />
        </div>
      );
      break
    case "/PC700":
      return (
        <div className="App">
          <NavBar />
          <BannerPC700 />
          <Footer />
        </div>
      );
      break
    case "/PC1000":
      return (
        <div className="App">
          <NavBar />
          <BannerPC1000 />
          <Footer />
        </div>
      );
      break
  }
  
}

export default App;
