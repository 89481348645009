
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerPC700 = () => {
  

  return (
    
    <section className="bannerPC500" id="home">
      <h1>PC da 700 €</h1>
      <Container>
        
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerPC500Link">
                  <h3>Primo PC: </h3>
                  <br></br>
                  <h5>-CPU: </h5>
                  <h7><a href="https://www.amazon.it/AMD-Ryzen-5-3600-Processore/dp/B07STGGQ18">AMD Ryzen 5 3600 - 199,90€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Scheda madre: </h5>
                  <h7><a href="https://www.amazon.it/MSI-B450M-PRO-M2-MAX/dp/B07VFM6RZK">MSI B450M-A PRO MAX - 64,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Memoria RAM: </h5>
                  <h7><a href="https://www.amazon.it/Corsair-Vengeance-LPX-Memorie-DDR4/dp/B0143UM4TC"> Corsair Vengeance LPX 16GB (2x8GB) DDR4 3200MHz - 71,90€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Scheda grafica: </h5>
                  <h7><a href="https://www.amazon.it/Nvidia-GeForce-GTX-1660-Super/dp/B081YV2WYJ">Nvidia GeForce GTX 1660 Super - 279,00€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Archiviazione: </h5>
                  <h7><a href="https://www.amazon.it/Kingston-A2000-SA2000M8-500G-Unità/dp/B07VYGJXCM">Kingston A2000 500GB NVMe SSD - 54,90€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Alimentatore: </h5>
                  <h7><a href="https://www.amazon.it/Corsair-CV550-80-Bronze-Alimentatore/dp/B08611M79W">Corsair CV550, 80+ Bronze 550W - 55,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Case: </h5>
                  <h7><a href="https://www.amazon.it/Aerocool-Cylon-Mini-Computer-Case/dp/B07NQMSQ4D">Aerocool Cylon Mini - 61,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Totale: 688€</h5>
                  <h7></h7>
                  <br></br>
                  <br></br>
                  <h3>FPS: </h3>
                  <br></br>
                  <h5>-GTA V: </h5>
                  <h7>tra i 70 e gli 80 fps a dettagli medi a 1080p</h7>
                  <br></br>
                  <br></br>
                  <h5>-Fortnite: </h5>
                  <h7>tra gli 80 e i 100 fps a dettagli medi a 1080p</h7>
                  <br></br>
                  <br></br>
                  <h5>-Minecraft: </h5>
                  <h7>tra i 120 e i 150 fps a dettagli elevati a 1080p</h7>
                  <br></br>
                  <br></br>
                  </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}