import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Footer = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="footer" id="footer">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="footer-bx wow zoomIn">
                        <h5>Budget</h5>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme footer-slider">
                            <div className="item">
                                <a href="/PC500"><h5>500 €</h5></a>
                            </div>
                            <div className="item">
                                <a href="/PC700"><h5>700 €</h5></a>
                            </div>
                            <div className="item">
                                <a href="/PC1000"><h5>1000 €</h5></a>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
