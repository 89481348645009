
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const BannerPC1000 = () => {
  

  return (
    
    <section className="bannerPC500" id="home">
      <h1>PC da 1000 €</h1>
      <Container>
        
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className="BannerPC500Link">
                  <h3>Primo PC: </h3>
                  <br></br>
                  <h5>-CPU: </h5>
                  <h7><a href="https://www.amazon.it/dp/B08166SLDF">AMD Ryzen 5 5600X - 327,00€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Scheda madre: </h5>
                  <h7><a href="https://www.amazon.it/dp/B089L7V3F3">ASUS PRIME B550M-A - 109,00€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Memoria RAM: </h5>
                  <h7><a href="https://www.amazon.it/Corsair-Vengeance-LPX-Memorie-DDR4/dp/B0143UM4TC"> Corsair Vengeance LPX 16GB (2x8GB) DDR4 3200MHz - 71,90€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Scheda grafica: </h5>
                  <h7><a href="https://www.amazon.it/Gigabyte-GeForce-GTX-1660-OC/dp/B07ZGY4Q4T/">NVIDIA GeForce GTX 1660 SUPER - 279,00€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Archiviazione: </h5>
                  <h7><a href="https://www.amazon.it/Kingston-A2000-SA2000M8-1000G-Unità/dp/B07VYMFVRL">Kingston A2000 1TB NVMe SSD - 94,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Alimentatore: </h5>
                  <h7><a href="https://www.amazon.it/Corsair-RM650-Alimentatore-Modulare-Certificazione/dp/B079H5DQ2Q">Corsair RM650, 80+ Gold 650W - 104,90€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Case: </h5>
                  <h7><a href="https://www.amazon.it/Cooler-Master-MasterBox-Tempered-MCB-B511D-KANN-S00/dp/B07HLBVJZD">Cooler Master MasterBox MB511 - 59,99€</a></h7>
                  <br></br>
                  <br></br>
                  <h5>-Totale: 1041€</h5>
                  <h7></h7>
                  <br></br>
                  <br></br>
                  <h3>FPS: </h3>
                  <br></br>
                  <h5>-GTA V: </h5>
                  <h7>tra i 70 e gli 80 fps a dettagli elevati a 1080p</h7>
                  <br></br>
                  <br></br>
                  <h5>-Fortnite: </h5>
                  <h7>tra gli 70 e i 80 fps a dettagli elevati a 1080p</h7>
                  <br></br>
                  <br></br>
                  <h5>-Minecraft: </h5>
                  <h7>oltre 100 fps a dettagli elevati a 1080p</h7>
                  <br></br>
                  <br></br>
                  </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}